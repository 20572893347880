import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">HTML best practices</AnchorLink>
      <AnchorLink mdxType="AnchorLink">CSS best practices</AnchorLink>
      <AnchorLink mdxType="AnchorLink">JS best practices</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "html-best-practices"
    }}>{`HTML best practices`}</h2>
    <h3 {...{
      "id": "structuring-code-and-navigation"
    }}>{`Structuring code and navigation`}</h3>
    <p>{`Think of code hierarchy when structuring your content so that screen readers and keyboard-only users can access interactive elements in a logical and predictable order via tabbing. Create the tab flow hierarchy by using the source code to arrange the keyboard navigation. Begin with the header, followed by the main navigation, then page navigation (from left to right, top to bottom), and end with the footer. The objective is to give keyboard users an intentional experience that is comparable to the experience of mouse users.`}</p>
    <h3 {...{
      "id": "use-semantic-html"
    }}>{`Use semantic HTML`}</h3>
    <p>{`Use native HTML elements as much as you can, and use them for their correct purpose. These elements have built-in accessibility benefits. They inform screen readers what they are and what they do, and standard interactive elements, such as a button, include keyboard functionality. Aside from making it accessible, this will also make it easier to develop and maintain, better on mobile, and good for search engine optimization.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button>Play Video</button>
<header></header>
<main></main>
<nav></nav>
<footer></footer>
<aside></aside>
<section></section>
<article></article>
`}</code></pre>
    <h3 {...{
      "id": "use-clear-language"
    }}>{`Use clear language`}</h3>
    <p>{`When adding content, consider cognitive disabilities, anyone whose native language isn’t the language your content is written in, and screen readers. When possible, avoid dashes, abbreviations, acronyms (at least the first time), and table layouts if a table is not needed. If abbreviating, use the native `}<inlineCode parentName="p">{`<abbr />`}</inlineCode>{` element with title attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`"9 to 5" "November" <abbr title="November">Nov</abbr>
`}</code></pre>
    <h3 {...{
      "id": "use-meaningful-text-labels"
    }}>{`Use meaningful text labels`}</h3>
    <p>{`Consider visually impaired people when labeling elements. Make sure there is textual context for screen readers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- Do this  -->
<a>Read more about pricing</a>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!--  Not this  -->
<a>Click here</a>
`}</code></pre>
    <h3 {...{
      "id": "making-accessible-data-tables"
    }}>{`Making accessible data tables`}</h3>
    <p>{`Always specify table headers with `}<inlineCode parentName="p">{`<th />`}</inlineCode>{` elements, and make sure they stand out. Utilize `}<inlineCode parentName="p">{`scope`}</inlineCode>{` attribute if necessary to specify if they are headers for rows or columns. Utilize alternative text along with tables for visually impaired users. `}<inlineCode parentName="p">{`<caption />`}</inlineCode>{` is preferred, but `}<inlineCode parentName="p">{`<table />`}</inlineCode>{` summary works too.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<table summary="Names and Ages of My Coworkers">
  <caption>
    Names and Ages of My Coworkers
  </caption>
  <thead>
    <tr>
      <th scope="col">Firstname</th>
      <th scope="col">Lastname</th>
      <th scope="col">Age</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Jane</td>
      <td>Smith</td>
      <td>22</td>
    </tr>
    <tr>
      <td>Tom</td>
      <td>West</td>
      <td>47</td>
    </tr>
  </tbody>
</table>
`}</code></pre>
    <h3 {...{
      "id": "making-accessible-data-visualizations"
    }}>{`Making accessible data visualizations`}</h3>
    <p>{`It is important to take into account visually impaired users when including data visualizations. Consider accompanying visuals with a data table as another alternative for users who rely on screen readers. It is also important to take into account color choices for color-blind users.`}</p>
    <h3 {...{
      "id": "multimedia-text-alternatives"
    }}>{`Multimedia text alternatives`}</h3>
    <p>{`Every image that is not decorative must include `}<inlineCode parentName="p">{`alt`}</inlineCode>{` text with a meaningful description of the image and a `}<inlineCode parentName="p">{`title`}</inlineCode>{` attribute. You can also utilize `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` along with `}<inlineCode parentName="p">{`id`}</inlineCode>{` attributes instead of `}<inlineCode parentName="p">{`alt`}</inlineCode>{` text. `}<strong parentName="p">{`If the image is decorative, use an empty `}<inlineCode parentName="strong">{`alt`}</inlineCode>{` attribute; otherwise the screen reader will read the whole image url if the `}<inlineCode parentName="strong">{`alt`}</inlineCode>{` is left out.`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- Example 1 -->

![](puppy.jpg" title="Sleeping Puppy)A sleeping puppy" />
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- Example 2 -->

![](puppy2.jpg" aria-labelledby="imagelabel" />
<p id="imagelabel">This is a picture of a cute puppy in cup</p>
`}</code></pre>
    <h3 {...{
      "id": "audio-alternatives"
    }}>{`Audio alternatives`}</h3>
    <p>{`Provide closed-captioning with videos or transcriptions of audio files.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<video controls>
  <source src="example.mp4" type="video/mp4" />
  <source src="example.webm" type="video/webm" />
  <track kind="subtitles" src="subtitles_english.vtt" srclang="en" />
</video>
`}</code></pre>
    <h3 {...{
      "id": "avoid-font-icon-libraries"
    }}>{`Avoid font icon libraries`}</h3>
    <p>{`Use SVG’s instead of font icon libraries, as those are not accessible.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<svg width="80" height="10">
  <line class="line" x1="200" y1="0" x2="0" y2="0" />
</svg>
`}</code></pre>
    <h3 {...{
      "id": "utilize-aria-accessible-rich-internet-application-roles-and-landmark-roles"
    }}>{`Utilize ARIA (Accessible Rich Internet Application) roles and landmark roles`}</h3>
    <p>{`ARIA roles convey the intent or meaning of an element to assistive technology. This helps users navigate when they can’t see the layout and provides further context about different functionalities.`}</p>
    <p>{`Landmark roles identify regions in a page, and act much like native HTML tags would when it comes to semantics. Signpost roles describe other information about a given element’s functionality on a page. These are especially helpful when building complex, custom interfaces or to reinforce semantics in native HTML elements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- landmark roles -->
<nav role="navigation"></nav>
<main role="main"></main>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- signpost roles -->
<div role="banner">This is a banner.</div>
<div role="tabgroup"><div role="tab"></div></div>
<div role="combobox"></div>
<div role="slider"></div>
<button role="button"></button>
`}</code></pre>
    <h2 {...{
      "id": "css-best-practices"
    }}>{`CSS best practices`}</h2>
    <h3 {...{
      "id": "css-rule-of-thumb"
    }}>{`CSS rule of thumb`}</h3>
    <p>{`You can style a page feature to fit your design, but don’t change it to the point that it doesn’t look or behave as expected.`}</p>
    <h3 {...{
      "id": "style-focus-indicators"
    }}>{`Style focus indicators`}</h3>
    <p>{`Add styling to tabbable elements on hover and focus, so that keyboard-only users can have a clear visual of where they are navigating. Never suppress the focus indicator altogether.`}</p>
    <h3 {...{
      "id": "hiding-elements"
    }}>{`Hiding elements`}</h3>
    <p>{`When hiding content from a screen reader, consider source order. Use `}<inlineCode parentName="p">{`visibility: hidden`}</inlineCode>{`, along with `}<inlineCode parentName="p">{`display: none`}</inlineCode>{` in your CSS.`}</p>
    <h2 {...{
      "id": "js-best-practices"
    }}>{`JS best practices`}</h2>
    <h3 {...{
      "id": "javascript-rule-of-thumb"
    }}>{`JavaScript rule of thumb`}</h3>
    <p>{`Don’t rely too much on JavaScript to generate HTML and CSS. Follow the “Unobtrusive JavaScript” principle, which means that JavaScript should be used to enhance functionality not build it entirely. Use your built-in browser functionality as much as possible. If you’re utilizing JavaScript to make complex UI features, use WAI-ARIA to make elements accessible. Examples of unobtrusive JavaScript include providing client-side form validation and custom controls for HTML5 `}<inlineCode parentName="p">{`<video />`}</inlineCode>{` elements that are accessible to keyboards.`}</p>
    <h3 {...{
      "id": "accessible-mouse-specific-events"
    }}>{`Accessible mouse-specific events`}</h3>
    <p>{`Double up mouse-specific events with other events for keyboard-only users.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const foo = document.querySelector('.foo-class');

foo.onmouseover = someFunction();

foo.onmouseout = anotherFunction();

foo.onfocus = someFunction();

foo.onblur = anotherFunction();
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      